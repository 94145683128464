//import { clear } from "../../../gulpfile.babel";
import {formatMoney} from "../utilities/Currency";
import {updateBisButtonDisplay} from "./KlaviyoBIS";
import ProductCardVariantSelector from './ProductCardVariantSelector';

const productCard = new ProductCardVariantSelector();

class SubscriptionSelector extends HTMLFieldSetElement {

  updateDisplay = () => {
    this.radioInput.forEach((radio) => {
      radio.addEventListener('change', this.handleRadioChange);
    });
  };

  // Has dispatch Event
  handleRadioChange = (evt) => {
    const activePanel = this.querySelector('.subscription-options__group.active');
    const activeDropdown = activePanel ? activePanel.querySelector('.subscription-options__dropdown') : null;
    const currentPanel = evt.target.closest('.subscription-options__group');
    const currentDropdown = currentPanel.querySelector('select');

    if (evt.target.parentElement.parentElement.querySelector('select')) {
      this.hiddenInput.value = evt.target.parentElement.parentElement.querySelector('select').value;
    } else {
      this.hiddenInput.value = '';
    }

    if (activePanel) {
      activePanel.classList.remove('active');
    }

    if (activeDropdown) {
      activeDropdown.classList.add('hide');
    }

    currentPanel.classList.add('active');

    if (currentDropdown) {
      currentDropdown.classList.remove('hide');
    }

    document.body.style.setProperty('--subscription-block-height-compressed', `${this.subscriptionBlock.firstElementChild.offsetHeight}px`);
    this.subscriptionBlock.style.height = null;

    this.dispatchEvent(
      new CustomEvent("handleRadioChange_Dispatch", {
        bubbles: true,
        detail: { 
          isActive: currentPanel.classList.contains('active'),
          isOneTime: this.querySelector('.subscription-options__group.active').hasAttribute('data-one-time'),
          price: this.querySelector('.subscription-options__group.active strong').innerText,
          compareAtPrice: this.querySelector('.subscription-options__group.active .price--compare').innerText,
          savings: this.querySelector('.subscription-options__group.active .subscription-options__label').innerText
        },
      }),
    );
  };

  registerVariantChangeHandler = () => {
    let selector;
    if (this.context === 'product') {
      selector = this.self.closest('[data-product-blocks]');
    } else {
      selector = this.closest('form');
    }
    selector.addEventListener('variant:changed', (evt) => {
      const variant = evt.detail.variant;
      this.updatePrice(variant);

      if (this.context === 'collection' && document.querySelector('.klaviyo-product-container')) {
        updateBisButtonDisplay(this.closest('form').querySelector('.klaviyo-product-container'), variant.available ? 'add' : 'remove');
        productCard.updateCartButton(this.closest('form').querySelector('.j-buy-product'), !variant.available);
      } else if (this.context === 'product' && document.querySelector('.klaviyo-product-container')) {
        document.querySelector('.klaviyo-bis-trigger').setAttribute('style', '');
        updateBisButtonDisplay(document.querySelector('.klaviyo-bis-trigger'), variant.available ? 'add' : 'remove');
      }
    });
  };

  registerHideVariantChangeHandler = () => {
    let selector;
    if (this.context === 'product') {
      selector = document.querySelectorAll('[data-hide-variant]');
    } else {
      selector = this.closest('form').querySelectorAll('[data-hide-variant]');
    }

    selector.forEach((input) => {
      input.addEventListener('change', (evt) => {

        const hideVariant = (evt.target.dataset.hideVariant === 'true');
        if (hideVariant) {
          this.parentNode.style.height = null;
          this.subscriptionOptionsHidden = true;
          this.hideSubscriptionSelector();
          this.classList.add('subscription-options--hidden');
          
        } else {
          
          if (this.subscriptionOptionsHidden) {
            this.selectSubscriptionOption();
          }
          this.subscriptionOptionsHidden = false;
          this.classList.remove('subscription-options--hidden');
          this.parentNode.style.height = null;

        }

        document.body.style.setProperty('--subscription-block-height-compressed', `${this.subscriptionBlock.firstElementChild.offsetHeight}px`);
      });
    });
  };

  // Has dispatch Event
  updatePrice = (variant) => {
    if (!variant || !variant.selling_plan_allocations.length) {
      return;
    }

    this.dispatchEvent(
      new CustomEvent("updatePrice_Dispatch", {
        bubbles: true,
        detail: { 
          price: variant.price,
          compare_at_price: variant.compare_at_price,
          selling_plan_price: variant.selling_plan_allocations[0].price,
          selling_plan_details: variant.selling_plan_allocations[0]
        },
      }),
    );
  };

  disableSubscriptionForm = (variant) => {
    if (variant.available) {
      this.querySelectorAll('.subscription-options__group').forEach((group) => {
        group.classList.remove('disabled');
      });
    }

    this.querySelectorAll('.subscription-options__group').forEach((group) => {
      group.classList.add('disabled');
    });
  };

  hideSubscriptionSelector = () => {
    this.subscriptionOptionsHidden = true;
    this.selectOneTimeOption();
    this.classList.add('subscription-options--hidden');
  };

  selectSubscriptionOption = () => {
    const radios = Array.from(this.radioInput);
    const nonEmptyRadio = radios.find((radio) => radio.value !== '');
    if (nonEmptyRadio) {
      nonEmptyRadio.checked = true;
      this.hiddenInput.value = this.planSelectOption.value;
    }

    this.querySelector('[data-one-time]').classList.remove('active');
    this.querySelector('.subscription-options__dropdown').classList.remove('hide');
    this.querySelector('.subscription-options__dropdown').closest('.subscription-options__group').classList.add('active');
  };

  selectOneTimeOption = () => {
    this.querySelector('.subscription-options__input[value=""]').checked = true;
    this.querySelector('[data-one-time]').classList.add('active');
    this.querySelector('.subscription-options__dropdown').classList.add('hide');
    this.querySelector('.subscription-options__dropdown').closest('.subscription-options__group').classList.remove('active');
    this.hiddenInput.value = "";
  };

  handleSelectChange = (evt) => {
    this.hiddenInput.value = evt.target.value;
  };

  emitVariantJson = () => {
    let selector;
    let variantRadios;
    if (this.context === 'product') {
      selector = this.parentElement.parentElement;
      variantRadios = selector.querySelectorAll('.block-variants input[type="radio"]');
    } else {
      selector = this.closest('form');
      variantRadios = selector.querySelectorAll('.product-form__variant-selector input[type="radio"]');
    }
    const handlerFunction = (evt) => {
      evt.stopPropagation();
      const variantJson = JSON.parse(evt.target.dataset.variant);
      const variantChangeEvent = new CustomEvent("variantChange", {
        detail: {variant: variantJson}
      });

      selector.dispatchEvent(variantChangeEvent);
    };

    variantRadios.forEach((radio) => {
      radio.addEventListener('change', (evt) => handlerFunction(evt));
    });
  };

  updateVariantPrice = document.addEventListener('variant:changed', (evt) => {
    console.log("updateVariantPrice: ", evt.detail)
    const price = evt.detail.variant.price;
    const compare_at_price = evt.detail.variant.compare_at_price;
    const updatedSubscriptionPrice = evt.detail.variant.selling_plan_allocations[0].price;

    // Subscription
    this.subscriptionPrice.innerText = `${formatMoney(updatedSubscriptionPrice, window.themeVariables.settings.moneyFormat, true)}`;
    this.compareAtPriceOneTime.innerText = `${formatMoney(price, window.themeVariables.settings.moneyFormat, true)}`;
    this.compareAtPriceSubscription.innerText = `${formatMoney(compare_at_price, window.themeVariables.settings.moneyFormat, true)}`;
    
    // Onetime
    this.oneTimePrice.innerText = `${formatMoney(price, window.themeVariables.settings.moneyFormat, true)}`;
    if (compare_at_price) {
      this.compareAtPriceOneTime.innerText = `${formatMoney(compare_at_price, window.themeVariables.settings.moneyFormat, true)}`;
    }
  });

  constructor() {
    super();
    this.self = this;
    this.context = this.dataset.context;
    this.radioInput = this.querySelectorAll('[data-plan-selector]');
    this.subscriptionBlock = document.querySelector('.block-subscription-widget');
    this.hiddenInput = this.querySelector('[name="selling_plan"]');
    this.subscriptionOptions = this.parentElement;
    this.subscriptionPrice = this.querySelector('[data-subscription-price]');
    this.oneTimePrice = this.querySelector('[data-one-time-price]');
    this.compareAtPriceOneTime = this.querySelector('[data-ot-compare-at-price]');
    this.compareAtPriceSubscription = this.querySelector('[data-sub-compare-at-price]');
    this.subscriptionSavings = this.querySelector('[data-subscription-savings] span');
    this.subscriptionSavingsLabel = document.querySelector('.product-meta__label-list.label-list');
    this.planSelectOption = this.querySelector('.subscription-options__dropdown');

    this.handleRadioChange = this.handleRadioChange.bind(this);
    this.subscriptionOptionsHidden = false;

    this.updateDisplay();
    this.registerVariantChangeHandler();
    this.registerHideVariantChangeHandler();
    this.planSelectOption.addEventListener('change', (evt) => this.handleSelectChange(evt));
    this.emitVariantJson();
    //this.updateVariantPrice;
  }
}

if (!customElements.get('subscription-selector')) {
  customElements.define('subscription-selector', SubscriptionSelector, {extends: 'fieldset'});
}

export default SubscriptionSelector;